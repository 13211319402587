// extracted by mini-css-extract-plugin
export var accordionTitle = "sample-module--accordionTitle--44f3e";
export var actionContainer = "sample-module--actionContainer--b94d6";
export var addToButtonContainer = "sample-module--addToButtonContainer--c7fa2";
export var attributeContainer = "sample-module--attributeContainer--a650b";
export var content = "sample-module--content--1e164";
export var description = "sample-module--description--54eb1";
export var details = "sample-module--details--eaa20";
export var gallery = "sample-module--gallery--fd10b";
export var heartFillContainer = "sample-module--heartFillContainer--88959";
export var hide = "sample-module--hide--94acf";
export var information = "sample-module--information--29ab5";
export var informationContainer = "sample-module--informationContainer--9cb3e";
export var priceContainer = "sample-module--priceContainer--eeab5";
export var quantityContainer = "sample-module--quantityContainer--5fa67";
export var root = "sample-module--root--7b2d6";
export var show = "sample-module--show--2f17f";
export var sizeContainer = "sample-module--sizeContainer--35a2e";
export var suggestionContainer = "sample-module--suggestionContainer--71e7c";
export var vendor = "sample-module--vendor--de5dc";
export var wishlistActionContainer = "sample-module--wishlistActionContainer--3f7b8";